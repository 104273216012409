import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import VideoList from 'components/VideoList';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function VideosPage(props) {
    let {
        data: {
            allContentfulVideo: { nodes: videos },
            contentfulPage,
        },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'video'}
            />
            <VideoList videos={videos} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulVideo(filter: { node_locale: { eq: $locale }, slug: {regex: "/^ad-.*/"} }) {
            nodes {
                ...ContentfulVideoFields
            }
        }
        contentfulPage(title: { eq: "Video" }, node_locale: { eq: $locale }) {
            ...ContentfulPageFields
        }
    }
`;
